import { DetailedResults, Meta, User, Survey, SurveyResponse, MemberSurveyResults, ClubDate } from "@/types";
import { axiosInstance } from "../clients";

/** Surveys */
async function listSurveys(params: { type?: string; query?: string; status?: string; sort?: string; dir?: string }): Promise<{ meta: Meta; data: { surveys: Survey[] } }> {
  return (
    await axiosInstance.get(`/v1/surveys`, {
      withCredentials: true,
      params,
    })
  ).data;
}

async function listSurveysTrainers(): Promise<{ meta: Meta; data: { trainers: User[] } }> {
  return (await axiosInstance.get(`/v1/surveys/trainers`, { withCredentials: true })).data;
}

async function getSurvey(survey_id: Survey["id"]): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}`, { withCredentials: true })).data;
}

async function getSurveyResults(event_id: number): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/results/${event_id}`, { withCredentials: true })).data;
}

async function getSurveyUsers(event_id: number, params: {query?: string; status?: string; sort?: string; dir?: string}): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/results/users/${event_id}`, { withCredentials: true, params } )).data;
}

async function getUserSurveyResponse(event_id: number, user_id: string): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/results/users/${event_id}/${user_id}`, { withCredentials: true })).data;
}

async function getSurveySatifaction(): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/satisfaction`, { withCredentials: true })).data;
}

async function getSurveySatifactionTrainers(params: {query?: string; status?: string; sort?: string; dir?: string}): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/satisfaction/trainers`, { withCredentials: true, params })).data;
}

async function getSurveySatifactionForCurrentTrainer(): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/satisfaction/trainers/summary/me`, { withCredentials: true })).data;
}

async function getSurveySatifactionClubOverall(): Promise<{ meta: Meta; data: { survey: Survey, overallScore: number } }> {
  return (await axiosInstance.get(`/v1/surveys/satisfaction/clubs/overall`, { withCredentials: true })).data;
}

async function getSurveySatifactionUsers(params: {query?: string; status?: string; sort?: string; dir?: string}): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`/v1/surveys/satisfaction/users`, { withCredentials: true, params })).data;
}

async function getSurveySatifactionClubs(params: {query?: string; status?: string; sort?: string; dir?: string}): Promise<{ meta: Meta; data: { survey: Survey } }> {
  return (await axiosInstance.get(`v1/surveys/satisfaction/clubs`, { withCredentials: true, params })).data;
}

async function updateSurvey(survey_id: Survey["id"], survey: Survey) {
  return (await axiosInstance.post(`/v1/surveys/${survey_id}`, { ...survey }, { withCredentials: true })).data;
}

async function listSurveyResponses(survey_id: Survey["id"]): Promise<{ meta: Meta; data: { responses: SurveyResponse[] } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}/responses/`, { withCredentials: true })).data;
}

async function getSurveyResultsTotal(survey_id: Survey["id"]): Promise<{ meta: Meta; data: { results: DetailedResults[] } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}/results`, { withCredentials: true })).data;
}

async function getSurveyResultsByEvent(survey_id: Survey["id"], event_id: string): Promise<{ meta: Meta; data: { results: DetailedResults[] } }> {
  return (await axiosInstance.get(`/v1/surveys/${survey_id}/results/${event_id}`, { withCredentials: true })).data;
}

async function getCourseSurveys(ids: number[]): Promise<{ meta: Meta; data: { surveys: { [key: string]: string } } }> {
  return (await axiosInstance.post(`/v1/surveys/events`, { ids }, { withCredentials: true })).data;
}

async function getCourseSurveyLinks(): Promise<{ meta: Meta; data: { training_survery_url: string, outstanding_surveys:[] } }> {
  return (await axiosInstance.post(`/v1/surveys/survey-links`, { withCredentials: true })).data;
}

async function getMemberSurveyResults(memberId: string): Promise<{ meta: Meta; data: MemberSurveyResults }> {
  return (await axiosInstance.get(`/v1/surveys/results/member/${memberId}`, { withCredentials: true })).data;
}

async function getMemberSessionFeedbackUrl(clubId: number, memberId: string): Promise<{ meta: Meta; data: { sessionFeedbackDue: boolean, session_feedback_url: string, survey_club_date: ClubDate} }> {
  return (await axiosInstance.get(`/v1/surveys/feedback/club/${clubId}/${memberId}`, { withCredentials: true })).data;
}

async function getLatestEvalationDatesForClubAndStudent(clubId: number, memberId: string): Promise<{ meta: Meta; data: { student_eval_due: boolean, facilitator_eval_due: boolean, parent_eval_due: boolean } }> {
  return (await axiosInstance.get(`v1/surveys/feedback/club/evaluation/dates/${clubId}/${memberId}`, { withCredentials: true })).data;
}

async function getMissingGoalProgressDates(clubId: number, memberId: string, goalId: number): Promise<{ meta: Meta; data: any }> {
  return (await axiosInstance.get(`v1/clubs/${clubId}/students/${memberId}/${goalId}/progress/available_dates`, { withCredentials: true })).data;
}

export default {
  listSurveys,
  listSurveysTrainers,
  getSurvey,
  getSurveyResults,
  getSurveyUsers,
  getUserSurveyResponse,
  getSurveySatifaction,
  getSurveySatifactionTrainers,
  getSurveySatifactionUsers,
  getSurveySatifactionForCurrentTrainer,
  getSurveySatifactionClubOverall,
  getSurveySatifactionClubs,
  updateSurvey,
  listSurveyResponses,
  getSurveyResultsTotal,
  getSurveyResultsByEvent,
  getCourseSurveys,
  getCourseSurveyLinks,
  getMemberSurveyResults,
  getMemberSessionFeedbackUrl,
  getLatestEvalationDatesForClubAndStudent,
  getMissingGoalProgressDates,
};
