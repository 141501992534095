export default {
  "new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuevo evento"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitio web"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comunidad"])},
  "email-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing por correo electrónico"])},
  "email-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferencias de correo electrónico"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel de control"])},
  "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
  "account-security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad de la cuenta"])},
  "add-a-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un nuevo usuario"])},
  "user-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil del usuario"])},
  "user-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de usuarios"])},
  "user-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de usuarios"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos"])},
  "course-prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisitos previos del curso"])},
  "course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas de curso"])},
  "add-a-new-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una nueva plantilla"])},
  "edit-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar plantilla de curso"])},
  "training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos de formación"])},
  "add-a-new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un nuevo evento"])},
  "training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento de formación"])},
  "user-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido del usuario"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos"])},
  "upload-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar recurso"])},
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurso"])},
  "edit-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar recurso"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuentos"])},
  "add-a-new-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un nuevo descuento"])},
  "add-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar Brick Club"])},
  "edit-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar descuento"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificados"])},
  "brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "view-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver miembro del club"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resumen"])},
  "course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descripción del curso"])},
  "registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistentes inscritos"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facturación"])},
  "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individual"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["curso"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelado"])},
  "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programado"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en borrador"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completado"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmado"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["publicado"])},
  "no-registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin inscritos"])},
  "minimum-not-met": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mínimo no alcanzado"])},
  "in review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en revisión"])},
  "unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no publicado"])},
  "event-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre del evento"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acciones"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formación"])},
  "trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formador"])},
  "course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plantilla del curso"])},
  "course-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nivel del curso"])},
  "course-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo de curso"])},
  "delivery-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["método de impartición"])},
  "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha de impartición"])},
  "available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["países disponibles"])},
  "date-and-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha y hora"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ubicación"])},
  "training-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vínculo a formación"])},
  "event-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["propietario del evento"])},
  "presenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presentadores"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precio"])},
  "payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vínculo de pago"])},
  "payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasarela de pago"])},
  "attendance-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límite de asistencia"])},
  "maximum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número máximo de participantes"])},
  "minimum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número mínimo de participantes"])},
  "waiting-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lista de espera"])},
  "go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha para publicar este evento"])},
  "registration-cut-off-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha límite de inscripción"])},
  "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hora de inicio"])},
  "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hora de finalización"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["región"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organización"])},
  "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["método de pago"])},
  "payment-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plataforma de pago"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pagado"])},
  "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completo"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ver"])},
  "event-submission-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias por enviar un evento. Play Included lo revisará y lo publicará en el sitio web. En el caso de eventos que requieran el pago de un canon, Play Included emitirá ahora una factura a pagar. El curso se publicará en el sitio web en la fecha que se haya indicado una vez recibido el pago. En el caso de eventos que no requieran el pago de un canon, Play Included aprobará el curso y lo publicará en el sitio web en la fecha que se haya indicado."])},
  "this-event-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este evento es"])},
  "course-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opiniones sobre el curso"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["valoración"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comentarios"])},
  "welcome-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Te damos la bienvenida de nuevo!"])},
  "your-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus eventos de formación"])},
  "submit-event-for-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar evento para aprobación"])},
  "edit-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar evento de formación"])},
  "save-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar evento de formación"])},
  "confirm-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar evento"])},
  "publish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar evento ya"])},
  "unpublish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar de publicar evento"])},
  "cancel-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar evento"])},
  "change-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar precio"])},
  "add-a-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un presentador"])},
  "add-another-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar otra fecha"])},
  "select-available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar países donde estará disponible"])},
  "make-training-event-go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear evento de formación en directo"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quitar"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelar"])},
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar cambios"])},
  "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar cuenta"])},
  "change-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cambiar contraseña"])},
  "add-session-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nueva nota de sesión"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "add-existing-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agregar participante existente"])},
  "save-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guardar participante"])},
  "edit-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar participante de brick club"])},
  "delete-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eliminar participante de brick club"])},
  "add-new-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agregar nuevo participante"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apellidos"])},
  "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de correo electrónico de la cuenta de Play Included"])},
  "email-address-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al modificar esto, será necesario iniciar sesión de nuevo"])},
  "your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tus funciones"])},
  "your-roles-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce cada empleo en una nueva línea"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["biografía"])},
  "facilitator-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajustes del facilitador"])},
  "trainer-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ajustes del formador"])},
  "register-of-practitioners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de facilitadores"])},
  "facilitator-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente este perfil no está visible en el sitio web. Si quieres aparecer, activa este ajuste."])},
  "public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al activarlo, tu perfil estará visible en el"])},
  "public-message-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["que incluye tu organización, tu país, tus funciones y el intervalo de edad de los miembros con los que trabajas, así como un vínculo a tus Brick Club."])},
  "works-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Con los que trabajas"])},
  "trainer-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente este perfil no está visible en el sitio web. Si quieres que se muestre, activa este ajuste."])},
  "language-and-region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idioma y región"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ciudad"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["idioma"])},
  "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["huso horario"])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código"])},
  "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoción"])},
  "starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comienza"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caduca"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["país"])},
  "limit-per-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límite por cliente"])},
  "limit-total-uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["límite de usos totales"])},
  "starts-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comienza a las"])},
  "expires-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caduca a las"])},
  "specific-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuario específico"])},
  "specific-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organización específica"])},
  "apply-to-courses-tagged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aplicar a cursos etiquetados"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["localización"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entorno"])},
  "first-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["primera fecha del club"])},
  "last-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["última fecha del club"])},
  "participants-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de participantes"])},
  "places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plazas disponibles"])},
  "session-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notas de la sesión"])},
  "age-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intervalo de edad"])},
  "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sesión"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nota"])},
  "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iniciales"])},
  "background-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notas de contexto"])},
  "brick-club-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notas del brick club"])},
  "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
  "course-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar al final de"])},
  "last-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["última modificación"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estado"])},
  "facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitador"])},
  "date-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["creado"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actualizado"])},
  "training-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniones sobre la formación"])},
  "submit-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enviar para revisión"])},
  "contact-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correo electrónico de contacto"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contacto"])},
  "contact-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["formador de contacto"])},
  "save-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guardar brick club"])},
  "edit-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar brick club"])},
  "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teléfono móvil"])},
  "for-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
  "survey-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["destinatarios de la encuesta"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caducidad"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nivel"])},
  "payment-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo de pago"])},
  "auto-renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["renovar automáticamente"])},
  "auto-renew-explainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la renovación automática está activada, tu suscripción se renovará automáticamente al caducar (se usarán los datos de la tarjeta facilitados en el momento de la reserva). Desactiva este ajuste si no quieres que se renueve la suscripción. Se te enviará un recordatorio por correo electrónico antes de que caduque tu suscripción."])},
  "auto-renew-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Siempre y cuando tu tarjeta no haya caducado."])},
  "child-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["resultados del menor"])},
  "club-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar cada semanas"])},
  "show-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostrar al progenitor"])},
  "discount-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código de descuento"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enviar"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archivos"])},
  "upload-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cargar archivos"])},
  "brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "move-this-child-to-new-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasladar a este menor a un nuevo Brick Club"])},
  "organisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["organizaciones"])},
  "first-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inicial del nombre"])},
  "family-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciales de los apellidos"])},
  "middle-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciales del nombre intermedio (opcional)"])},
  "parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["progenitor/cuidador"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correo electrónico"])},
  "edit-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar participante del club"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["te damos la bienvenida"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["principal"])},
  "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adicional"])},
  "medication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medicación"])},
  "thank-you-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracias"])},
  "brick-club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros del Brick Club"])},
  "view-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver miembro"])},
  "Edit-Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar miembro"])},
  "scheduled-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos programados"])},
  "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservado"])},
  "order-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido n.º"])},
  "placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["realizado"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo"])},
  "website-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del sitio web"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
  "my-children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menores a mi cargo"])},
  "my-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menor a mi cargo"])},
  "edit-child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar datos del menor"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agregado"])},
  "edit-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar menor"])},
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["capacidad"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["importe"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cantidad"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuario"])},
  "invoice-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factura emitida"])},
  "add-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar fechas"])},
  "add-multiple-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar varias fechas"])},
  "capacity-of-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacidad del club"])},
  "add-new-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nuevo miembro del club"])},
  "add-another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agregar otro"])},
  "save-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guardar miembro del club"])},
  "edit club member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar miembro del club"])},
  "show-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar progenitor/cuidador del miembro"])},
  "brick-club-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota del Brick Club para el miembro"])},
  "visible-to-member-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible para el progenitor/cuidador del miembro"])},
  "my-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis Brick Club"])},
  "manage-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar Brick Club"])},
  "add-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nota para el miembro"])},
  "visible-to-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visible para el progenitor/cuidador del miembro"])},
  "facilitator-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de verificación y notas de la sesión"])},
  "please-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona"])},
  "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["participante"])},
  "save-survey-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guardar-datos-de-encuesta"])},
  "no-responses-to-this-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta encuesta no tiene ninguna respuesta"])},
  "view-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver sesiones"])},
  "view-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver progreso"])},
  "club-delete-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta opción eliminará todas las fechas, las notas de las sesiones y las notas de los estudiantes relacionadas con este Brick Club"])},
  "this-club-is-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este club es privado"])},
  "this-club-is-public-and-not-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona esta opción si no quieres aceptar solicitudes de reserva"])},
  "this-club-is-public-and-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta opción mostrará tu correo electrónico y sitio web"])},
  "please-select-visibility-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona el nivel de visibilidad"])},
  "do-not-list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mostrar en el directorio de los Brick Club"])},
  "list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar en el directorio de los Brick Club"])},
  "list-on-directory-as-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar en el directorio de los Brick Club como club privado"])},
  "smartsurvey-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alerta-encuesta-lista-verificación-sesión"])},
  "remove-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar participante"])},
  "move-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasladar miembro a otro Brick Club"])},
  "date-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha agregada"])},
  "add-a-new-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un nuevo descuento"])},
  "verifone-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de producto de Verifone"])},
  "merchant-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del comercio"])},
  "percent-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de descuento"])},
  "edit-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar descuento"])},
  "license-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de la licencia"])},
  "issued by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emitida por"])},
  "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asistente"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["evento"])},
  "subscription-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de la suscripción"])},
  "delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegados"])},
  "invoice-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["factura pagada"])},
  "payment-gateway-customer-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de cliente en pasarela de pago"])},
  "payment-gateway-order-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de pedido en pasarela de pago"])},
  "event-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de evento"])},
  "club-attendance-help-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde aquí puedes hacer un seguimiento de la asistencia de los miembros del Brick Club."])},
  "total-no-of-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número total de sesiones"])},
  "total-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["% total"])},
  "group-notes-for-session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas grupales de la sesión"])},
  "session-checklist-scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuaciones de la lista de verificación de la sesión"])},
  "view-childs-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver progreso del menor"])},
  "secondary-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico secundario"])},
  "secondary-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono secundario"])},
  "event-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["identificador de evento"])},
  "get-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtener ayuda"])},
  "your-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus formadores"])},
  "medication-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Toma o tiene el menor alguna medicación que el facilitador debería conocer?"])},
  "additional-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Hay algo más que el facilitador debería saber sobre el menor para ayudarlo a tener una experiencia positiva en el Brick Club?"])},
  "progress-and-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso y comentarios"])},
  "member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios de la sesión con el miembro"])},
  "goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de objetivos"])},
  "goal-tracking-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde aquí puedes ver y realizar un seguimiento de los objetivos en los que está trabajando el menor en el Brick Club."])},
  "current-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de objetivo actual"])},
  "programme-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios del programa"])},
  "member-session-feedback-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí puedes revisar los comentarios relativos al menor en su Brick Club."])},
  "view-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver comentarios"])},
  "view-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver resultados"])},
  "club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros del Club"])},
  "club-members-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde aquí puedes administrar los miembros de tu Brick Club y ver sus perfiles."])},
  "session-checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de verificación de la sesión"])},
  "member-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistencia de los miembros"])},
  "member-progress-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es hora de evaluar tu Brick Club."])},
  "session-checklist-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lista de verificación de la sesión puede ayudarte a comprobar con qué fidelidad te ajustas a la metodología de Brick-by-Brick®."])},
  "default-brick-club-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué les ha parecido la sesión de hoy a los miembros del Brick Club?"])},
  "group-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas grupales"])},
  "back-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a"])},
  "member-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de los miembros"])},
  "email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es el correo electrónico que usarás para iniciar sesión en la cuenta de Play Included y en el que recibirás las comunicaciones de Play Included."])},
  "show-facilitator-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar perfil del facilitador"])},
  "show-trainer-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar perfil del formador"])},
  "your-profile-is-currently-visible-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente tu perfil está visible en el sitio web como formador."])},
  "your-profile-is-currently-visible-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente tu perfil está visible en el sitio web como facilitador."])},
  "add-a-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una nota sobre el miembro"])},
  "add-member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar comentarios sobre la sesión con el miembro"])},
  "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["objetivo"])},
  "add-a-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un objetivo"])},
  "date-you-set-this-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha en que fijaste este objetivo"])},
  "category-of-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría del objetivo"])},
  "add-a-member-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un objetivo de participante"])},
  "archive-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar objetivo"])},
  "completion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
  "achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logrado"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivar"])},
  "add-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar progreso"])},
  "goal-progress-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy calificaríamos el progreso de los miembros del Brick Club hacia este objetivo como:"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cerrar"])},
  "goal-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas del objetivo"])},
  "group-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de objetivos grupales"])},
  "add-goal-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde aquí puedes actualizar el progreso hacia este objetivo."])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Seguro que quieres hacerlo?"])},
  "goal-tracking-alert-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega tres objetivos como máximo para que sigan siendo pertinentes."])},
  "goal-tracking-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde aquí puedes revisar el progreso hacia cada objetivo del Brick Club."])},
  "no-active-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay objetivos activos"])},
  "start-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación de inicio"])},
  "end-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puntuación final"])},
  "steps-travelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasos recorridos"])},
  "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesiones"])},
  "no-archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay objetivos archivados"])},
  "member-session-feedback-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde aquí puedes agregar y revisar los comentarios relativos al menor en la sesión del Brick Club. Se compartirán con los familiares del miembro del Brick Club. Desde aquí puedes ver y revisar los permisos para compartir."])},
  "arrow-keys-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa las flechas para ver más fechas."])},
  "date-goal-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha en que se fijó este objetivo"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
  "child-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas sobre el menor"])},
  "child-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios sobre la sesión con el menor"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso"])},
  "archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos archivados"])},
  "child-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniones del menor"])},
  "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gráfico"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla"])},
  "parent-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniones del progenitor"])},
  "facilitator-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniones del facilitador"])},
  "renewal-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de renovación"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovación automática"])},
  "no-smartsurvey-access-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente no tienes acceso a esta función."])},
  "member-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas sobre el miembro"])},
  "member-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opiniones del miembro"])},
  "member-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso del miembro"])},
  "club-name-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este nombre se proporciona solo a modo de referencia."])},
  "club-address-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se mostrará en el directorio de los Brick Club si la visibilidad de tu club está configurada como “Pública”."])},
  "view-brick-club-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver en directorio de los Brick Club"])},
  "club-email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es el correo electrónico que se mostrará en el registro de facilitadores y en el directorio de los Brick Club si has configurado tu perfil y tus Brick Club como visibles."])},
  "host-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País anfitrión"])},
  "what-does-this-mean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué significa esto?"])},
  "end-goal-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar objetivo"])},
  "files-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos (haz clic para descargar)"])},
  "parent-only-field-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información que el progenitor/cuidador quiere que conozcas para ayudar al miembro a disfrutar de una experiencia positiva en el Brick Club."])},
  "first-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de primera impartición"])},
  "event-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar a eventos con etiqueta (opcional)"])},
  "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso"])},
  "brick-club-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del Brick Club"])},
  "publish-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fecha de publicación"])},
  "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceso de incorporación"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
  "parent-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona esta opción si eres un progenitor/cuidador al que le gustaría acceder a recursos para progenitores/cuidadores."])},
  "professional-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona esta opción si eres un profesional al que le gustaría acceder a recursos para profesionales y, posiblemente, inscribirse en cursos profesionales."])},
  "parent-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta función aún no está disponible en el Brick Club del menor a tu cargo. Si pasara a estar disponible, te lo comunicaremos por correo electrónico."])},
  "facilitator-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta función beta no está disponible para tu tipo de suscripción en este momento. Cuando esté disponible, te lo comunicaremos por correo electrónico."])},
  "trainer-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta función beta no está disponible para tu tipo de suscripción en este momento. Cuando esté disponible, te lo comunicaremos por correo electrónico."])},
  "facilitator-evaluation-nudge-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué te ha parecido la experiencia del Brick Club? Por favor, completa el cuestionario de opinión “Finalización del Brick Club”."])},
  "complete-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completar encuesta"])},
  "view-session-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver asistencia y notas de la sesión"])},
  "add-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar presentador"])},
  "no-presenters-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay presentadores disponibles"])},
  "brick-club-schedule-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aunque es necesario agregar todas las fechas de tu Brick Club, para la configuración inicial basta con agregar al menos una. Puedes agregar el resto después de la configuración editando un Brick Club."])},
  "no-places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no hay plazas disponibles"])},
  "place-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plaza disponible"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["funciones"])},
  "affiliate-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de afiliado"])},
  "funds-recieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondos recibidos:"])},
  "view-all-clubs-length-brick-clubs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ver los ", _interpolate(_list(0)), " Brick Club"])},
  "view-all-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los facilitadores"])},
  "facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitadores"])},
  "please-select-a-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona un Brick Club"])},
  "no-brick-clubs-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ningún Brick Club disponible"])},
  "attendance-change-warning-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten en cuenta que, al cambiar la fecha aquí, también se cambiará la fecha programada en tu Brick Club. Cámbiala únicamente si la sesión del Brick Club se ha trasladado otra fecha."])},
  "record-member-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar comentarios sobre la sesión con el miembro y sus objetivos"])},
  "select-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar país"])},
  "save-selected-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar país seleccionado"])},
  "tax-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libre de impuestos"])},
  "total-meta-pagination-total_entries-items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " artículos en total"])},
  "manage-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar perfil"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["público"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privado"])},
  "viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(visible en el sitio web)"])},
  "not-viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(no visible en el sitio web)"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["factura"])},
  "not-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no pagada"])},
  "tax-registration-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de identificación fiscal"])},
  "purchase-order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de pedido"])},
  "not-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no recibido"])},
  "payment-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe del pago"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["impuestos"])},
  "redeemed-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencia canjeada"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buscar"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pendiente"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caducada"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ninguna"])},
  "manual-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción manual"])},
  "subscribe-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscribir usuario"])},
  "view-all-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los pedidos"])},
  "this-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta semana"])},
  "this-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este mes"])},
  "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana pasada"])},
  "view-all-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los cursos"])},
  "add-a-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar progenitor/cuidador"])},
  "upload-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar recurso"])},
  "add-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar evento"])},
  "there-was-an-error-fetching-the-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error al recuperar el evento"])},
  "there-was-an-error-updating-this-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error al actualizar este evento"])},
  "event-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento actualizado"])},
  "there-was-an-error-updating-event-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se produjo un error al actualizar la visibilidad del evento"])},
  "event-visibility-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad del evento actualizada"])},
  "show-on-google-maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar en Google Maps"])},
  "event-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del evento"])},
  "this-will-cancel-the-event-and-notify-all-registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esto cancelará el evento y lo comunicará a todos los asistentes inscritos."])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilimitado"])},
  "confirm-event-cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar cancelación del evento"])},
  "error-updating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al actualizar el evento"])},
  "i-e-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es decir, PayPal"])},
  "includes-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Incluye impuestos?"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisa"])},
  "please-add-a-direct-link-to-help-the-user-find-the-venue-e-g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega un vínculo directo para ayudar al usuario a encontrar la localización (p. ej.,"])},
  "google-maps-optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Maps) (opcional)."])},
  "please-select-a-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige un país"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
  "address-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea de dirección 2"])},
  "address-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea de dirección 1"])},
  "selectedtemplate-type-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vínculo a ", _interpolate(_list(0))])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opcional"])},
  "event-summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen del evento"])},
  "you-are-editing-a-event-status-event-any-changes-will-effect-this-event": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Estás editando un evento ", _interpolate(_list(0)), ". Cualquier cambio que realices afectará a este evento."])},
  "error-creating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al crear el evento"])},
  "event-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento creado"])},
  "please-select-a-country-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige un país"])},
  "submit-training-event-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar evento de formación para revisión"])},
  "save-as-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar como borrador"])},
  "selectedtemplate-delivery-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vínculo a ", _interpolate(_list(0))])},
  "all-times-are-in-short_timezone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Todas las horas son en ", _interpolate(_list(0))])},
  "for-more-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para más información."])},
  "play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included"])},
  "there-are-currently-no-templates-available-to-you-please-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualmente no hay plantillas disponibles para ti; ponte en contacto con"])},
  "use-this-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar esta plantilla"])},
  "to-get-started-please-select-a-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para empezar, selecciona una plantilla de curso"])},
  "introductory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicial"])},
  "continuing-professional-development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuando desarrollo profesional"])},
  "drop-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reunión en línea"])},
  "Training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formación"])},
  "add-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar plantilla de curso"])},
  "view-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver plantilla"])},
  "key-programme-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos principales del programa"])},
  "moodle-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["curso de moodle"])},
  "registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reglas y método de inscripción"])},
  "please-add-atleast-1-rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega al menos una regla de inscripción."])},
  "edit-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar reglas de inscripción"])},
  "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precios"])},
  "please-add-atleast-one-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega al menos un país y su precio."])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editar"])},
  "add-a-country-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nuevo precio para país"])},
  "countries-and-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países y precios"])},
  "subscription-level-on-completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de suscripción al finalizar"])},
  "merchant-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de producto del comercio"])},
  "merchant-product-code-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si introduces un código de producto de Verifone, no se aplicarán los precios de los países ni la tabla de precios. En su lugar, se usarán los precios de 2Checkout."])},
  "merchant-product-code-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTA: No dejes de completar los países siguientes para asegurarte de que el producto aparezca en el sitio web."])},
  "save-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla de curso"])},
  "select-course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona una descripción para el curso"])},
  "view-course-in-cms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver descripciones de cursos en el CMS"])},
  "storyblok-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción de Storyblok"])},
  "template-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilla creada"])},
  "error-creating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al crear plantilla"])},
  "template-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilla actualizada"])},
  "error-updating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al actualizar plantilla"])},
  "new-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo curso"])},
  "country-and-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País y precio"])},
  "excluding-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA no incluido"])},
  "select-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar todo"])},
  "user-must-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario debe ser"])},
  "add-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar reglas de inscripción"])},
  "latest-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas plantillas de curso"])},
  "see-all-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todas las plantillas de curso"])},
  "upcoming-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximos eventos de formación"])},
  "see-all-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los eventos de formación"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En línea"])},
  "no-date-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay fechas disponibles"])},
  "event-invoice-marked-as-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factura del evento marcada como emitida"])},
  "event-invoice-marked-as-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factura del evento marcada como pagada"])},
  "discount-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del descuento"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
  "delete-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar descuento"])},
  "error-deleting-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al eliminar descuento"])},
  "discount-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descuento eliminado"])},
  "add-a-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar descuento"])},
  "discount-used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0))])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etiquetas"])},
  "enter-new-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce nuevas etiquetas separadas por espacio"])},
  "tag-name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre de la etiqueta no puede estar vacío"])},
  "tag-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta creada"])},
  "error-creating-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al crear etiqueta"])},
  "current-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción actual"])},
  "subscription-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción actualizada"])},
  "error-updating-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al actualizar la suscripción"])},
  "user-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario suscrito"])},
  "error-subscribing-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al suscribir usuario"])},
  "include-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA incl."])},
  "exclude-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IVA excl."])},
  "course-prerequisites-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requisitos previos del curso"])},
  "to-attend-the-booking-event-name-course-please-accept-our-mandatory-course-prerequisites": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Para asistir al curso ", _interpolate(_list(0)), ", acepta nuestros requisitos previos obligatorios."])},
  "view-club-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver detalles del club"])},
  "this-user-has-no-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este usuario todavía no ha organizado ningún Brick Club"])},
  "course-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del curso"])},
  "completed-event-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Completado ", _interpolate(_list(0))])},
  "in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En curso"])},
  "view-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver certificado"])},
  "this-user-has-no-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este usuario todavía no se ha inscrito en ningún evento de formación"])},
  "you-have-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Tienes ", _interpolate(_list(0)), " plaza(s) canjeable(s) y ", _interpolate(_list(1)), " pendiente(s) para este tipo de evento"])},
  "add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar usuario al curso"])},
  "organisation-has-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Esta organización tiene ", _interpolate(_list(0)), " plaza(s) canjeable(s) y ", _interpolate(_list(1)), " pendiente(s) para este tipo de evento"])},
  "active-courses-by-play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos activos de Play Included"])},
  "your-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus facilitadores"])},
  "view-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver cursos"])},
  "courses-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Sabías que Play Included ofrece una variedad de cursos CPD adicionales?"])},
  "your-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus Brick Club"])},
  "view-all-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los Brick Club"])},
  "continue-events-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continúa tu experiencia Brick-by-Brick® inscribiéndote en el curso de facilitador del programa Brick-by-Brick."])},
  "update-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar suscripción"])},
  "subscription-expires": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La suscripción caduca el ", _interpolate(_list(0))])},
  "subscription-expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La suscripción caducó el ", _interpolate(_list(0))])},
  "current-membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripción actual"])},
  "view-all-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los formadores"])},
  "view-all-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los usuarios"])},
  "copy-event-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar vínculo del evento"])},
  "trainer-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precios del formador"])},
  "club-note-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí puedes dejar notas sobre una sesión del Brick Club. Estas notas no serán visibles para nadie más que tú. Puedes escribir notas sobre miembros específicos y optar por compartirlas con los progenitores/cuidadores en la pestaña “Miembros”."])},
  "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio"])},
  "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de fin"])},
  "export-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar datos"])},
  "create-manual-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear reserva manual"])},
  "notes-only-visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las notas solo son visibles para ti y, si lo deseas, para el progenitor o cuidador del miembro"])},
  "view-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver recurso"])},
  "view-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver recursos"])},
  "no-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Actualmente no hay recursos para ", _interpolate(_list(0))])},
  "role-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Recursos para ", _interpolate(_list(0))])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover"])},
  "manage-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar licencia"])},
  "credit-debit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjeta de crédito/débito"])},
  "single-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva individual"])},
  "group-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva de grupo"])},
  "answer-choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de respuesta"])},
  "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
  "subscription-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la suscripción"])},
  "no-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguna organización"])},
  "manual-add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar usuario manualmente al curso"])},
  "user-is-ineligible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario no cumple los requisitos"])},
  "discount-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe del descuento"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
  "select-marketing-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar preferencias de marketing"])},
  "marketing-preferences-copy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Te damos la bienvenida a tu portal de Play Included! Estamos encantados de verte por aquí. Antes de que eches un vistazo al portal, dedica un instante a hacernos saber tus preferencias en cuanto a las comunicaciones. Nos encantaría mantenerte informado con noticias interesantes, consejos y recursos. Aunque recibirás automáticamente los correos electrónicos relativos a tu suscripción o tus compras, necesitamos tu consentimiento para enviarte otro tipo de mensajes."])},
  "i-would-like-to-receive-emails-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me gustaría recibir correos electrónicos de Play Included sobre:"])},
  "marketing-preferences-copy-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nos tomamos muy en serio tu privacidad. Tus datos personales se tratarán de forma confidencial de acuerdo con la ", _interpolate(_list(0)), ". Puedes cambiar tus preferencias en cualquier momento visitando “Mi perfil” en el portal."])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidad"])},
  "requires-a-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Se requiere una suscripción?"])},
  "subscription-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe de la suscripción"])},
  "tax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe de impuestos"])},
  "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe total"])},
  "renewal-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo renovación"])},
  "renewals-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo renovaciones"])},
  "affiliate_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de afiliado"])},
  "template_field_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Se han deshabilitado algunos campos porque este curso tiene ", _interpolate(_list(0)), " eventos en el sistema. Si necesitas cambiar alguno de los campos deshabilitados, deberás crear una nueva plantilla y un nuevo evento."])},
  "no-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin cambios"])},
  "payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del pago"])},
  "discount-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de descuento"])},
  "discount-percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porcentaje de descuento"])},
  "discounted-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe descontado"])},
  "pretax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importe antes de impuestos"])},
  "please-enter-a-payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce un vínculo de pago"])},
  "please-enter-a-payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduce una pasarela de pago"])},
  "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licencias"])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suscripción"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suscripción"])},
  "One-off fee Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producto de tarifa única"])},
  "Bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservas"])},
  "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nuevo usuario"])},
  "license-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licencia"])},
  "added-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregado por"])},
  "redeem-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canjear plaza"])},
  "first-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primera fecha"])},
  "group-booking-financial-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información bancaria de reserva de grupo"])},
  "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plazas"])},
  "net-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio neto"])},
  "delegate-within-own-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegado dentro de mi organización"])},
  "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservas"])},
  "transaction-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de transacción"])},
  "order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del pedido"])},
  "refunded?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Reembolsado?"])},
  "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolsado"])},
  "back-to-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a nuestro sitio web"])},
  "all-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde el inicio"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ventas"])},
  "abandoned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonado"])},
  "select-your-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu idioma"])},
  "step-of-four": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["paso ", _interpolate(_list(0)), " de 4"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
  "parent-onboard-permissions-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El portal de Play Included es el lugar desde donde administramos y supervisamos los Brick Club. En Play Included tenemos como objetivo ofrecer a los miembros de los Brick Club una experiencia de alta calidad, y creemos que, para alcanzar ese objetivo, es esencial proporcionarte las herramientas que te permitan administrar y supervisar los Brick Club. Puedes leer más sobre cómo administramos los datos relacionados con los miembros de los Brick Club y los propios Brick Club aquí."])},
  "parent-onboarding-attendance-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy de acuerdo en permitir al facilitador del Brick Club usar el portal de Play Included para registrar la asistencia del menor a mi cargo y agregar notas relativas a él para cada sesión. Estos datos serán visibles para el facilitador del Brick Club, para ti como familiar del miembro, para cualquier administrador de los Brick Club y para Play Included."])},
  "parent-onboarding-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy de acuerdo en permitir al facilitador registrar el progreso del menor en Smart Survey y en el portal de Play Included. Esta información se compartirá contigo (y con cualquier otro progenitor/cuidador, cuando proceda) en el portal de Play Included. Los datos también serán visibles para el administrador del Brick Club y Play Included."])},
  "parent-onboarding-no-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No doy mi consentimiento para que el Facilitador registre la asistencia ni el progreso en los Brick Club. Entiendo que no se registrará ni la asistencia del menor a los Brick Club ni su progreso."])},
  "parent-onboarding-aware-of-participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El menor es consciente de que está participando en el programa Brick-by-Brick® y se le ha agregado en el portal de Play Included (cuando proceda). Si el menor no está de acuerdo con participar, te rogamos que te pongas en contacto con el facilitador del Brick Club. Tú y el menor tenéis la oportunidad de elegir qué datos compartís con los facilitadores de los Brick Club, los administradores y Play Included, pudiendo administrar esos permisos en la sección “Menores a mi cargo” del portal, dentro del apartado “Administrar perfil”"])},
  "parent-onboarding-aware-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí."])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["siguiente"])},
  "parent-onboarding-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comencemos."])},
  "parent-onboarding-started-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para asegurarnos de ofrecerte la mejor experiencia en los Brick Club, nos gustaría confirmar algunos datos sobre el menor a tu cargo."])},
  "parent-onboarding-started-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incluir esta información significa que las iniciales del menor se usarán en todo el portal, lo que os ofrecerá una experiencia más personal e individualizada a ti y al menor. Puedes cambiar la información en cualquier momento accediendo al perfil del menor en el portal."])},
  "parent-onboarding-started-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Puedes leer acerca de cómo trata Play Included los datos personales en la ", _interpolate(_list(0))])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí."])},
  "child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del menor"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atrás"])},
  "parent-carer-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del progenitor o cuidador"])},
  "parent-carer-details-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A continuación recopilaremos tus datos. Solo se usarán para ponernos en contacto contigo en relación con el Brick Club y en caso de actualización de los datos del menor en nuestro sistema. También recibirás novedades por correo electrónico. Puedes editar tus preferencias en materia de comunicaciones en el apartado “Mi perfil”."])},
  "parent-carer-details-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Puedes actualizar, editar o modificar tu información de contacto en cualquier momento en ", _interpolate(_named("strong")), "."])},
  "parent-carer-details-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La información relativa a cómo almacena y trata Play Included los datos de contacto está disponible en la ", _interpolate(_list(0)), "."])},
  "member-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil del miembro"])},
  "member-profile-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queremos que los Brick Club sean espacios divertidos y seguros para sus miembros. Contar con un poco de información adicional sobre los miembros antes de que acudan al Brick Club puede ayudar a los facilitadores a crear un entorno acogedor que garantice su seguridad y bienestar."])},
  "member-profile-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Esta información, que debe incluir únicamente los datos necesarios para lograr un funcionamiento fluido y seguro del Brick Club, solo se compartirá con el facilitador de dicho Brick Club y la organización del facilitador (si procede). Tales datos no serán visibles para Play Included; puedes leer acerca de cómo trata Play Included los datos en la ", _interpolate(_list(0)), "."])},
  "medication-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, no agregues notas identificativas aquí (p. ej., dirección, nombre completo del menor o fecha de nacimiento)."])},
  "make-primary-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como cuidador principal"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
  "change-password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para editar tu contraseña, te redirigiremos a nuestro sistema de inicio de sesión único (SSO)."])},
  "all-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las funciones"])},
  "resources-pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " artículos en total"])},
  "create-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nuevo Brick Club"])},
  "show-all-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar solo eventos en mi organización"])},
  "show-my-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar eventos para los que he reservado delegados"])},
  "view-my-delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver mis delegados"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso no autorizado"])},
  "annual-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripciones anuales"])},
  "view-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver suscripción"])},
  "background-notes-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta información la ha agregado el progenitor/cuidador del menor y no puede ser editada por los facilitadores."])},
  "member-medication-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Toma el miembro algún tipo de medicación?"])},
  "parent-carer-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimiento del progenitor/cuidador"])},
  "parent-carer-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El progenitor/cuidador ha dado su consentimiento para que el facilitador registre notas relativas a la asistencia y la sesión en el portal de Play Included"])},
  "parent-carer-progress-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El progenitor/cuidador ha dado su consentimiento para que el facilitador registre el progreso en el portal Play Included"])},
  "parent-carer-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El progenitor/cuidador ha dado su consentimiento para que el facilitador registre notas relativas a la asistencia y la sesión en el portal de Play Included"])},
  "parent-carer-no-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El progenitor/cuidador no ha dado su consentimiento para que se registre la asistencia ni el progreso en el portal de Play Included"])},
  "member-background-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta información la ha agregado el progenitor/cuidador del menor y no puede ser editada por los facilitadores."])},
  "member-medication-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Toma el miembro algún tipo de medicación?"])},
  "member-additional-notes-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información que el progenitor/cuidador quiere que conozcas para ayudar al miembro a disfrutar de una experiencia positiva en el Brick Club."])},
  "parent-carer-subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consentimiento del progenitor/cuidador"])},
  "parent-attendance-session-notes-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El progenitor/cuidador ", _interpolate(_list(0)), " ha dado su consentimiento para que el facilitador registre notas relativas a la asistencia y la sesión en el portal de Play Included"])},
  "parent-survey-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["El progenitor/cuidador ", _interpolate(_list(0)), " ha dado su consentimiento para que el facilitador registre el progreso en el portal de Play Included"])},
  "parent-no-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El progenitor/cuidador no ha dado su consentimiento para que se registre la asistencia ni el progreso en el portal de Play Included"])},
  "your-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus reservas"])},
  "user_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo de usuarios"])},
  "training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento de formación"])},
  "training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos de formación"])},
  "user_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de usuarios"])},
  "add_a_new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un nuevo evento"])},
  "brick_clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "all-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los tipos"])},
  "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
  "facilitation-aid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos de facilitación"])},
  "club-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiales de promoción y marketing"])},
  "supporting-asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos del Brick Club"])},
  "training-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recursos de formación"])},
  "all-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las etiquetas"])},
  "all-languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los idiomas"])},
  "cpd-for-certified-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos CPD para facilitadores"])},
  "add_to_moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar a Moodle"])},
  "create-account-in-moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta en Moodle"])},
  "max-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máx. de asistentes"])},
  "attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistentes"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminado"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserva"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido"])},
  "order-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido en sitio web"])},
  "access-training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder a formación"])},
  "training-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado de la formación"])},
  "certified-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso de facilitador certificado"])},
  "advanced-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso de facilitador avanzado"])},
  "product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de producto"])},
  "add-a-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar una nueva organización"])},
  "add-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar nueva organización"])},
  "organisation-ran-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizado por"])},
  "course-blocked-pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, compruebe que el pago se ha realizado y que se han aceptado los prerrequisitos."])},
  "upcoming-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximos cursos"])},
  "not-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No iniciado"])},
  "product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de producto"])},
  "discounted_product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de producto con descuento"])},
  "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de descuento"])},
  "subscriptions_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suscripciones adquiridas"])},
  "turn-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La renovación automática está activada: haz clic aquí para desactivarla"])},
  "turn-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La renovación automática está desactivada: haz clic aquí para desactivarla"])},
  "directory-website-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es la URL que se mostrará en el registro de facilitadores y en el directorio de los Brick Club si has configurado tu perfil y tus Brick Club como visibles."])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condado"])},
  "view_certificate_pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver certificado (PDF)"])},
  "my-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis suscripciones"])},
  "play-included-mailing-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de correo de Play Included"])},
  "certified-facilitators-trainees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitadores certificados en formación"])},
  "advanced-facilitators-trainees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitadores avanzados en formación"])},
  "new-products-and-development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevos productos y desarrollo"])},
  "play-included-news-and-updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias y actualizaciones de Play Included"])},
  "training-courses-and-cpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cursos de formación y desarrollo profesional continuo (DPC)"])},
  "trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formadores"])},
  "advanced-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitadores avanzados"])},
  "training-partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socios de formación"])},
  "parent-carers-of-brick-club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padres/tutores de miembros del Brick Club"])},
  "parentcarer-news-and-updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias y actualizaciones para padres/tutores"])},
  "certified-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitadores certificados"])},
  "play-at-home-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembro de Play at Home"])},
  "resource-card-header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Recurso ", _interpolate(_list(0))])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembro de Play At Home"])},
  "paid-professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fundamentos del Brick Club"])},
  "certified-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitador Certificado"])},
  "certified-facilitator-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitador Certificado en Prácticas"])},
  "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembro Introductorio de Juego Incluido"])},
  "brick-club-family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familia del Brick Club"])},
  "advanced-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitador Avanzado"])},
  "advanced-facilitator-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitador Avanzado en Prácticas"])},
  "l3-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendiz Nivel 03"])},
  "l4a-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instructor"])},
  "l4b-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendiz Nivel 04b"])},
  "l3-specialist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especialista Nivel 03"])},
  "l4a-trainee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprendiz Nivel 04a"])},
  "l4b-mentor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentor Nivel 04b"])},
  "facilitator-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador de Grupo Brick-by-Brick®"])},
  "courses-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador de Cursos"])},
  "users-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador de Usuarios"])},
  "brick-club-admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador de Brick Clubs"])},
  "trainer-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organización de Instructores"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador"])},
  "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inglés"])},
  "chinese": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chino"])},
  "danish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danés"])},
  "spanish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])}
}